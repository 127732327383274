var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('search-box',{attrs:{"label":"* Item Pai","popup-label":"Selecione um Item","idPai":_vm.value.idPai,"tituloPai":_vm.value.tituloPai,"hidden-clear":true,"value":_vm.itensPais,"loader-fn":_vm.loaderItensPais,"item-key":"idPai","item-text":"tituloPai","rules":[
            this.$validators.notNullOrUndefined
        ]},on:{"update:idPai":function($event){return _vm.$set(_vm.value, "idPai", $event)},"update:id-pai":function($event){return _vm.$set(_vm.value, "idPai", $event)},"update:tituloPai":function($event){return _vm.$set(_vm.value, "tituloPai", $event)},"update:titulo-pai":function($event){return _vm.$set(_vm.value, "tituloPai", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Item Pai encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"* Título","dense":"","rules":[
                      this.$validators.string.required,
                        function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                        function (v) { return this$1.$validators.string.lessThanOrEquals(v, 200); }
                      ]},model:{value:(_vm.value.titulo),callback:function ($$v) {_vm.$set(_vm.value, "titulo", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.titulo"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('lookup-dominio',{attrs:{"label":"* Aparece no Menu?","hidden-clear":true,"hint":"Informe se é um item de menu","type":_vm.lookups.flgVisivel,"rules":[
                      this.$validators.notNullOrUndefined
                    ]},model:{value:(_vm.value.flgVisivel),callback:function ($$v) {_vm.$set(_vm.value, "flgVisivel", $$v)},expression:"value.flgVisivel"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"* EndPoint","dense":"","disabled":_vm.value.flgVisivel==='N'},model:{value:(_vm.value.url),callback:function ($$v) {_vm.$set(_vm.value, "url", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.url"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"* Recurso","dense":"","rules":[
                      this.$validators.string.required,
                        function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                        function (v) { return this$1.$validators.string.lessThanOrEquals(v, 200); }
                      ]},model:{value:(_vm.value.link),callback:function ($$v) {_vm.$set(_vm.value, "link", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.link"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('search-box-multiple',{staticClass:"search-box-cursos",attrs:{"label":"* NívelAcesso","popup-label":"Selecione os cursos","loader-fn":_vm.loaderNivelAcesso,"item-key":"key","item-text":"valor","rules":[
            this.$validators.notNullOrUndefined ]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"curso-chip v-chip",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"item-description"},[_vm._v(_vm._s(item.valor))]),_c('div',{staticClass:"remove-button",on:{"click":function($event){return _vm.removeNivel(item)}}},[_c('v-btn',{staticClass:"ma-0 pa-0",attrs:{"x-small":"","icon":""}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1)])]}},{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Curso encontrada ")])]},proxy:true}]),model:{value:(_vm.value.niveisAcesso),callback:function ($$v) {_vm.$set(_vm.value, "niveisAcesso", $$v)},expression:"value.niveisAcesso"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }