<template>
  <base-view
      @click:add="criar"
      :permiteCadastrar="permiteCadastrar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <CreateEditGestaoAcessoDialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <GestaoAcessoDataTable ref="dataTable"
                             @click:edit="editar"
                             @click:remove="remover"
                         :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import GestaoAcessoDataTable from "../../components/shared/datatables/GestaoAcessoDataTable";
import BaseView from "../BaseView";
import {findOne,remove} from "../../api/gestaoAcesso";
import CreateEditGestaoAcessoDialog from "../../components/shared/dialogs/CreateEditGestaoAcessoDialog";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions, mapGetters} from "vuex";
import {ApiError} from "../../api/core";

export default {
  name: "index",
  components: {CreateEditGestaoAcessoDialog, BaseView, GestaoAcessoDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      permiteCadastrar: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [     
        {
          name: 'Descricao',
          column: 'titulo',
          type: 'text'
        },
        {
          name: 'Recurso',
          column: 'link',
          type: 'text'
        },
        {
          name: 'Visível',
          column: 'flgVisivel',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.FLG_PADRAO
          })
        },  
        {
          name: 'Perfil Acesso',
          column: 'nivelAcesso',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.NIVEL_ACESSO_USUARIO
          })
        },                  
      ]
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
  },  
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {
        id: null,
        link: "",
        titulo: "",
        nivelAcesso: "",
        descNicelAcesso: "",
        niveisAcesso: [],
        idPai: null,
        tituloPai: null,
        ordem: null,
        url: "",
        icon: "",
        flgVisivel: "",
        descFlgVisivel: "",
      };
      this.editDialogOpened = true;
    },
    async editar(acesso) {
      const data = await this.getGestaoAcesso(acesso.id);
      this.item = data;      
      this.editDialogOpened = true;
    },
    async getGestaoAcesso(id) {
      if (id){
        try {
          this.error = null;
          this.loading = true;
          const page = await findOne(id);
          let acesso = page.dados[0];
          console.log(acesso);
          return acesso;
        } catch (err) {
          this.error = err;
        } finally {
          this.loading = false;
        }
      }
    },    
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Item Acesso removido com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {           
          this.$toast.error(`Não foi possível remover o Item Acesso.`, {
            timeout: 6000
          })
        }
      }
    },
	  async getPermissoes(){
      this.permiteCadastrar = await this.getPermissaoPorItemPerfil("Modalidade.cadastro",this.nivelUsuarioLogado);
    },
  },
  mounted() {
    this.getPermissoes();
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>